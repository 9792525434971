import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import NextLink from 'next/dist/client/link';
import { NextSeo } from 'next-seo';
import { appReducerSelector } from '../features/app/state/selectors';
import { waitAllSagasComplete, wrapper } from '../store';
import { CitySelection } from '../components/CitySelection/CitySelection';
import { getHomePageData, getHomePromotions } from '../features/home/state/actions';
import { homeSelector } from '../features/home/state/selectors';
import { PromotionsCarousel } from '../components/PromotionsCarousel/PromotionsCarousel';
import { ArticlePreview } from '../components/ArticlePreview/ArticlePreview';
import { ContactNumberLink } from '../components/ContactNumberLink/ContactNumberLink';
import { Spinner } from '../components/loaders/Spinner/Spinner';
import { createDefaultSEOData } from '../services/routing';
import styles from './styles/Home.module.scss';

interface IHomeProps {
}

const Home: React.FC<IHomeProps> = () => {
  const { cities: citiesData } = useSelector(appReducerSelector);
  const {
    data, promotions, isLoading, isLoadingData,
  } = useSelector(homeSelector);
  const { entities } = citiesData;
  const { title, text, phone } = data;

  const cities = useMemo(() => (
    Object.values(entities)
  ), [entities]);

  return (
    <main className={styles.main}>
      <NextSeo {...createDefaultSEOData()} />
      <div className={styles.home}>
        <NextLink href="/">
          <a className={styles.logoLink}>
            <img
              className={styles.logoImg}
              src="/svg/logo.svg"
              alt="Logo"
            />
          </a>
        </NextLink>
        {
          isLoading
            ? <Spinner className={styles.spinner} />
            : <PromotionsCarousel className={styles.promotions} promotions={promotions} />
        }
        <CitySelection className={styles.citySelection} title="Оберіть своє місто" cities={cities} />
        { isLoadingData && (<Spinner className={styles.spinner} />) }
        { !isLoadingData && title && text && (
          <ArticlePreview
            className={styles.aboutUs}
            title={title}
            content={text}
          />
        ) }
        { !isLoadingData && phone && (
          <ContactNumberLink
            className={styles.contactNumber}
            phoneNumber={phone}
          />
        ) }
      </div>
    </main>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(async ({ store }) => {
  store.dispatch(getHomePromotions());

  store.dispatch(getHomePageData());

  await waitAllSagasComplete(store);
});

export default Home;
