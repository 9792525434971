import React from 'react';
import classNames from 'classnames';
import { IPromotions } from '../../types/promotion';
import { CarouselItem, Carousel, ICarouselProps } from '../Carousel/Carousel';
import styles from './PromotionsCarousel.module.scss';

export interface IPromotionsCarouselProps extends Partial<ICarouselProps> {
  promotions: IPromotions;
}

const settings = {
  loop: true,
  autoplay: {
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
  },
  pagination: {
    clickable: true,
  },
};

export const PromotionsCarousel: React.FC<IPromotionsCarouselProps> = ({ className, promotions, ...restProps }) => (
  <Carousel
    className={classNames(styles.carousel, className)}
    {...settings}
    {...restProps}
  >
    { promotions.map(({ name, bannerLink, fullSliderImagePath }) => (
      <CarouselItem key={name + bannerLink + fullSliderImagePath}>
        <a
          href={bannerLink}
          rel="noreferrer"
          target="_blank"
        >
          <img
            className={styles.slideImg}
            src={fullSliderImagePath}
            alt={name}
          />
        </a>
      </CarouselItem>
    )) }
  </Carousel>
);
