import React from 'react';
import classNames from 'classnames';
import { DetailsLink, IDetailsLinkProps } from '../DetailsLink/DetailsLink';
import styles from './ArticlePreview.module.scss';

export interface IArticlePreviewProps {
  className?: string;
  title: string;
  content: string;
  linkProps?: Partial<IDetailsLinkProps>;
}

export const ArticlePreview: React.FC<IArticlePreviewProps> = ({
  className,
  title,
  content,
  linkProps,
}) => {
  const { className: linkClassName } = linkProps || {};

  return (
    <div className={classNames(styles.articlePreview, className)}>
      <h3 className={styles.title}>{ title }</h3>
      <p className={styles.text}>
        { content }
      </p>
      { linkProps && (
        <DetailsLink
          {...linkProps}
          className={classNames(styles.link, linkClassName)}
        >
          Детальніше
        </DetailsLink>
      ) }
    </div>
  );
};
